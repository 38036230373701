<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.materialCode" class="input" placeholder="备件编码" clearable></el-input>
      <el-button type="primary" @click="currentChange(1)" :loading="loading">查询</el-button>
      <el-button type="primary" @click="dialogVisible = true">添加</el-button>
    </el-row>
    <el-table :data="dataList" border style="width: 100%" stripe>
      <el-table-column align="center" prop="materialCode" label="备件编码"></el-table-column>
      <el-table-column align="center" prop="model" label="型号"></el-table-column>
      <el-table-column align="center" prop="fixtureSpec" label="夹具规格"></el-table-column>
      <el-table-column align="center" prop="cableSpec" label="排线规格"></el-table-column>
      <el-table-column align="center" prop="interfaceSpec" label="接口规格"></el-table-column>
      <el-table-column align="center" prop="keyboardSpec" label="键盘规格"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间"></el-table-column>
      <el-table-column align="center" label="操作" >
        <template slot-scope="scope">
          <el-button @click="deleteInfo(scope.row)" type="danger" size="mini">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>

    <el-dialog title="添加键盘机种" :visible.sync="dialogVisible" :close-on-click-modal="false" width="700px" :before-close="handleClose">
      <el-form v-model="formData" label-width="200px">
        <el-form-item label="备件编码：">
          <el-input v-model.trim="formData.materialCode" placeholder="请输入备件编码" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="型号：">
          <el-input v-model.trim="formData.model" placeholder="请输入型号" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="夹具规格：">
          <el-input v-model.trim="formData.fixtureSpec" placeholder="请输入夹具规格" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="排线规格：">
          <el-input v-model.trim="formData.cableSpec" placeholder="请输入排线规格" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="接口规格：">
          <el-input v-model.trim="formData.interfaceSpec" placeholder="请输入接口规格" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="键盘规格：">
          <el-input v-model.trim="formData.keyboardSpec" placeholder="请输入键盘规格" style="width: 350px" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="add">确定</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
export default {
  name: "KeyboardModel",

  data() {
    return {
      dialogVisible:false,
      loading:false,
      formData:{},
      queryInfo: {
        allotNo: '',
        materialCode: '',
        assetSn: '',
        pageNo: 1
      },
      pageSize:0,
      total:0,
      dataList:[],

    }
  },
  created() {
    this.search();

  },
  methods: {

    currentChange(pageNo) {
      this.pageNo = pageNo;
      this.search()
    },

    async search() {
      this.loading = true;
      const {data: res} = await this.$axios.post('keyboardModel/queryList', this.queryInfo);
      this.loading = false;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.dataList = res.data.records;
      this.total = res.data.total;
      this.pageSize = res.data.size;
    },
    add(){
      if(!this.formData.materialCode){
        return this.$message.error('备件编码不能为空')
      }
      if(!this.formData.model){
        return this.$message.error('型号不能为空')
      }
      if(!this.formData.fixtureSpec){
        return this.$message.error('夹具规格不能为空')
      }
      if(!this.formData.cableSpec){
        return this.$message.error('排线规格不能为空')
      }
      if(!this.formData.interfaceSpec){
        return this.$message.error('接口规格不能为空')
      }
      if(!this.formData.keyboardSpec){
        return this.$message.error('键盘规格不能为空')
      }

      this.loading = true;
      this.$axios.post('keyboardModel/add', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.$message.success("添加成功");
        this.search();
        this.handleClose()
        this.formData = {}
      }, error => {
        this.loading = false;
        return this.$message.error('添加失败，' + error.message);
      });


    },
    handleClose() {
      this.dialogVisible = false;
    },
    deleteInfo(row){
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('keyboardModel/delete', row.materialCode).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.search();
        })
      })

    }

  }
}
</script>

<style scoped>
.input{
  width: 250px;
  margin-right: 10px
}

</style>